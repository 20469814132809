import React from 'react';
import { Link } from "gatsby"

import './styles.scss';

const FourZeroFour = () => {
  return (
    <>
      <section className="four-zero-four">
        <div className="content">
          <h2 className="text-center title">404</h2>
          <p className="text-center paragraph">Δεν βρέθηκε κάτι, πίσω στην <Link className="link" to="/">Αρχική</Link>.</p>
        </div>
        <div className="ocean">
          <div className="wave"></div>
          <div className="wave"></div>
        </div>
      </section>
    </>
  )
}

export default FourZeroFour;